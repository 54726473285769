<template>
  <div class="flex flex-col gap-4 rounded-lg p-4 bg-gray-100 text-base-content">
    <div class="case-creation flex gap-4 justify-between">
      <span class="case-creation__name font-semibold">
        {{ fieldLabel }}
      </span>
      <div class="flex items-center gap-4">
        <div class="flex items-center gap-2">
          <Checkbox
            @input="changeblockVisibile"
            v-model="field.block_visibility"
            :disabled="shouldRenderContent"
          />
          <span class="font-semibold">Block Visibility</span>
        </div>
        <div class="flex items-center gap-2">
        <Checkbox
          @input="changeCaseCreation"
          v-model="field.use_in_case_creation"
          :disabled="shouldRenderContent"
        />
        <span class="font-semibold">Use in Case Creation</span>
      </div>
      </div>
    </div>
    <div class="flex items-start gap-4 justify-between">
      <Permissions
        :field="field"
        v-on="$listeners"
        :queryData="queryData"
      />
      <Visibility
        :field="field"
        :roles="roles"
        v-on="$listeners"
        :queryData="queryData"
      />
    </div>
  </div>
</template>

<script>
import Permissions from './permissions.vue';
import Visibility from './visibility.vue';
import Checkbox from "@/components/checkbox-alt";

export default {
  name: 'cascaded-field-attributes',
  props: {
    field: {
      type: Object,
      required: true,
    },
    queryData: {
      type: Object,
      default: () => ({}),
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },
  components: {
    Permissions,
    Visibility,
    Checkbox,
  },
  computed: {
    fieldLabel() {
      return ['Candidate Consent','Candidate Optional Consent'].includes(this.field.field_type) ?
        this.field.field_type.replace('Candidate','') :
        this.field.field_label;
    },
    shouldRenderContent() {
    return !this.queryData || this.queryData.action == 'view';
  }
  },
  methods: {
    changeCaseCreation() {
      this.$emit('changeCaseCreation');
    },
    changeblockVisibile() {
      this.$emit('changeblockVisibile');
    },
  },
}
</script>

<style lang="scss" scoped>

</style>