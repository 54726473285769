<template>
  <div class="visibility rounded-lg p-3 bg-white">
    <div class="visibility__title py-1 font-semibold text-sm">Report Visibility</div>
    <div class="visibility__options text-xs">
      <label
        class="option flex gap-2 items-center p-1.5 cursor-pointer"
        v-for="option in roles"
        :key="option.id"
      >
        <input
          :value="option.id"
          type="radio"
          class="w-5 h-5 flex-shrink-0"
          :name="field.id + '_visibility'"
          v-model="field.report_visibility"
          @input="$emit('change')"
          :disabled="shouldRenderContent"
        />
        <span>{{ option.name }}</span>
      </label>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    field: {
      type: Object,
      required: true,
    },
    queryData: {
      type: Object,
      default: () => ({}),
    },
    roles: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {}
  },
  computed:{
    shouldRenderContent() {
    return !this.queryData || this.queryData.action == 'view';
  }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
.permissions-table {
  td, th {
    @apply p-1.5;
    text-align: center; 
    vertical-align: middle;
  }
}
</style>